<template>
  <div>
    <b-modal size="sm" hide-footer v-model="modalExchangeRateEdit" class="w-100" title="Editar tipo de cambio">
        <b-form id="Form" @submit.prevent="Validate">
              <b-row>
                  
                  <b-col md="12">
                    <b-form-group label="Fecha:">
                      <b-form-input disabled class="text-center" type="date" v-model="exchange_rate.date"></b-form-input>
                      <small v-if="errors.date" class="form-text text-danger">Ingrese una fecha</small>
                    </b-form-group>
                  </b-col>
          
                  <b-col md="6">
                    <b-form-group label="Dolares:">
                      <b-form-input class="text-right" type="number" step="any" v-model="exchange_rate.usd"></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col md="6">
                    <b-form-group label="Pesos:">
                      <b-form-input class="text-right" type="number" step="any" v-model="exchange_rate.clp"></b-form-input>
                    </b-form-group>
                  </b-col>


                  <b-col md="12">
                    <b-form-group label="Estado :">
                      <b-form-select v-model="exchange_rate.state" :options="state"></b-form-select>
                    </b-form-group>
                  </b-col>

           
                  <b-col md="12">
                    <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                  </b-col>
              </b-row>
            </b-form>
    </b-modal>

    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>
<style>


</style>


<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
var moment = require("moment");
import LoadingComponent from './../../pages/Loading'
export default {
  name: "ModalsProduct",
  components:{
      LoadingComponent,
      Keypress: () => import('vue-keypress'),
  },
  data() {
    return {
        modalExchangeRateEdit:false,
        isLoading: false,
        module: 'ExchangeRate',
        role:3,
        id_exchange_rate:'',
        exchange_rate: {
          id_exchange_rate: "",
          id_user: "",
          date: moment(new Date()).local().format("YYYY-MM-DD"),
          pen: (1).toFixed(2),
          usd: (0).toFixed(2),
          clp: (0).toFixed(2),
          state: 1,
        },
        state:[
          {value:1,text:'Activo'},
          {value:0,text:'Inactivo'},
        ],
        errors: {
          date: false,
          usd:false,
          clp:false,
        },
        validate: false,
    };
  },
  created (){
    
  },
  mounted () {
    EventBus.$on('ModalExchangeRateEditShow', (id_exchange_rate) => {
      this.modalExchangeRateEdit = true;
      this.id_exchange_rate = id_exchange_rate;
      this.ViewExchangeRate();
    });

  },
  methods: {
    ViewExchangeRate,
    EditExchangeRate,
    Validate,
  },
  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function ViewExchangeRate() {
  let me = this;
  let url = me.url_base + "exchange-rate/view/"+this.id_exchange_rate;
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: {"Content-Type": "application/json",token: me.token,  module: me.module, role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.exchange_rate.id_exchange_rate = response.data.result.id_exchange_rate;
        me.exchange_rate.id_user = response.data.result.id_user;
        me.exchange_rate.date = response.data.result.date;
        me.exchange_rate.usd = response.data.result.usd;
        me.exchange_rate.usd = response.data.result.usd;
        me.exchange_rate.clp = response.data.result.clp;
        me.exchange_rate.state = response.data.result.state;
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}
function EditExchangeRate() {
  let me = this;
  let url = me.url_base + "exchange-rate/edit";
  me.exchange_rate.id_user = me.user.id_user;
  let data = me.exchange_rate;
  me.isLoading = true;
  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json",token: me.token,  module: me.module, role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.modalExchangeRateEdit = false;
        EventBus.$emit('ListExchangeRate');
        me.exchange_rate.usd = parseFloat(response.data.result.usd).toFixed(3);
        me.exchange_rate.clp = parseFloat(response.data.result.clp).toFixed(3);
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

function Validate() {
  this.errors.date = this.exchange_rate.date.length == 0 ? true : false;
  this.errors.usd = this.exchange_rate.usd.length == 0 ? true : false;
  this.errors.clp = this.exchange_rate.clp.length == 0 ? true : false;
  this.errors.state = this.exchange_rate.state.length == 0 ? true : false;
  if (this.errors.name) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.usd) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.clp) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.state) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }

  let me = this;

   if (!this.validate) {
    Swal.fire({
      title: "Esta seguro de modificar el tipo de cambio ?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Estoy de acuerdo!",
    }).then((result) => {
      if (result.value) {
        this.EditExchangeRate();
      }
    });

  }
}
</script>
